import { getAvailableProductsOnce } from "@/plugins/firebase";
import { getCommerceIdCategories } from "@/plugins/firebase";
import { getCommerceProfile } from "@/plugins/axios";

const state = () => ({
  loading: true,
  currentVisibleSectionId: "product-section-0",
  products: [],
  categoriesFirebase: [],
  profile: {
    shopLogoUrl: null,
    menuLogoUrl: null,
    secondScreenLogoUrl: null,
    facebookUrl: null,
    instagramUrl: null,
    shopName: null,
    isPaymentEnabled: false,
    isWebOrdersEnabled: false,
    isKioskModeEnabled: false,
    isStockValidationEnabled: false,
    isOpen: null,
    isDeliveryOpen: null,
    operationMode: 1, //1 == WebOrders as default
    backgroundMenuUrl: null,
    productListId: null,
    childShops: null,
    isMinimumAmountEnabled: false,
    minimumAmount: null,
    isDeliveryTimeEnabled: false,
    isPickupTimeEnabled: false,
    minimumDeliveryTimeInMinutes: null,
    maximumDeliveryTimeInMinutes: null,
    minimumPickupTimeInMinutes: null,
    maximumPickupTimeInMinutes: null,
    helpContactCenter: null,
    shippingCost: null,
    isShippingCostEnabled: true,
    hasToShowUnavailableProducts: false,
    listOfStocks: null,
  },
  originName: null,
});

const mutations = {
  SET_CURRENT_VISIBLE_SECTION_ID(state, sectionId) {
    state.currentVisibleSectionId = sectionId;
  },
  SET_FIREBASE_CATEGORIES(state, categories) {
    state.categoriesFirebase = categories;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_ORIGIN_NAME(state, originName) {
    state.originName = originName;
  },
  SET_PROFILE(
    state,
    {
      menuLogoUrl,
      secondScreenLogoUrl,
      shopLogoUrl,
      facebookUrl,
      instagramUrl,
      shopName,
      isPaymentEnabled,
      isWebOrdersEnabled,
      isKioskModeEnabled,
      isMercadoPagoEnabled,
      isOtherPaymentWayEnabled,
      isModoEnabled,
      shopDescription,
      pickUpPoints,
      isTipEnabled,
      isStockValidationEnabled,
      isOpen,
      isDeliveryOpen,
      operationMode,
      backgroundMenuUrl,
      productListId,
      childShops,
      isMinimumAmountEnabled,
      minimumAmount,
      isDeliveryTimeEnabled,
      isPickupTimeEnabled,
      minimumDeliveryTimeInMinutes,
      maximumDeliveryTimeInMinutes,
      minimumPickupTimeInMinutes,
      maximumPickupTimeInMinutes,
      helpContactCenter,
      shippingCost,
      isShippingCostEnabled,
      deliveryZone,
      urlPopUpImage,
      popUpUrl,
      uuidPopUp,
      hasToShowUnavailableProducts,
      listOfStocks,
    }
  ) {
    state.profile.shopLogoUrl = shopLogoUrl;
    state.profile.menuLogoUrl = menuLogoUrl;
    state.profile.secondScreenLogoUrl = secondScreenLogoUrl;
    state.profile.facebookUrl = facebookUrl;
    state.profile.instagramUrl = instagramUrl;
    state.profile.isPaymentEnabled = isPaymentEnabled;
    state.profile.isWebOrdersEnabled = isWebOrdersEnabled;
    state.profile.isMercadoPagoEnabled = isMercadoPagoEnabled;
    state.profile.isOtherPaymentWayEnabled = isOtherPaymentWayEnabled;
    state.profile.isModoEnabled = isModoEnabled;
    state.profile.shopDescription = shopDescription;
    state.profile.pickUpPoints = pickUpPoints;
    state.profile.childShops = childShops;
    state.profile.isMinimumAmountEnabled = isMinimumAmountEnabled;
    state.profile.minimumAmount = minimumAmount;
    state.profile.isDeliveryTimeEnabled = isDeliveryTimeEnabled;
    state.profile.isPickupTimeEnabled = isPickupTimeEnabled;
    state.profile.minimumDeliveryTimeInMinutes = minimumDeliveryTimeInMinutes;
    state.profile.maximumDeliveryTimeInMinutes = maximumDeliveryTimeInMinutes;
    state.profile.minimumPickupTimeInMinutes = minimumPickupTimeInMinutes;
    state.profile.maximumPickupTimeInMinutes = maximumPickupTimeInMinutes;
    state.profile.helpContactCenter = helpContactCenter;
    state.profile.isShippingCostEnabled = isShippingCostEnabled;
    state.profile.shippingCost = shippingCost;
    state.profile.deliveryZone = deliveryZone;
    state.profile.urlPopUpImage = urlPopUpImage;
    state.profile.popUpUrl = popUpUrl;
    state.profile.uuidPopUp = uuidPopUp;
    state.profile.hasToShowUnavailableProducts = hasToShowUnavailableProducts;

    if (pickUpPoints) {
      state.profile.selectedPickUpPoint = pickUpPoints.find(
        (x) => x.isSelectedByDefault
      );
    }

    state.profile.isTipEnabled = isTipEnabled;
    state.profile.isKioskModeEnabled = isKioskModeEnabled;
    state.profile.isStockValidationEnabled = isStockValidationEnabled;
    state.profile.isOpen = isOpen;
    state.profile.isDeliveryOpen = isDeliveryOpen;
    state.profile.operationMode = operationMode;
    state.profile.shopName = shopName;
    state.profile.backgroundMenuUrl = backgroundMenuUrl;
    state.profile.productListId = productListId;
    state.profile.listOfStocks = listOfStocks;
  },
};

const getters = {
  categoriesFirebase: (state) => {
    return state.categoriesFirebase;
  },
  groupedProductsByType: (state, getters) => {
    let groupedMenuProducts = [];
    // console.log("products", state.products);
    state.products.forEach((element) => {
      const typeExists = groupedMenuProducts.find(
        (type) => type.name === element.department
      );
      if (!typeExists) {
        const id = String(groupedMenuProducts.length);

        groupedMenuProducts.push({
          name: element.department,
          orderNumber:
            getters.categoriesFirebase.filter(
              (x) => x.name == element.department
            )[0]?.orderNumber ?? 0,
          id: `product-section-${id}`,
          domScrollTarget: `scroll-product-section-${id}`,
          products: [],
        });
      }
      groupedMenuProducts
        .find((type) => type.name === element.department)
        .products.push(element);
    });

    groupedMenuProducts = groupedMenuProducts.sort((a, b) => {
      if (
        a.orderNumber === "" ||
        a.orderNumber === undefined ||
        a.orderNumber === null
      ) {
        if (a.name < b.name) {
          return -1;
        } else return 1;
      }
      if (
        b.orderNumber === "" ||
        b.orderNumber === undefined ||
        b.orderNumber === null
      ) {
        if (a.name < b.name) {
          return -1;
        } else return 1;
      }
      if (parseInt(a.orderNumber) < parseInt(b.orderNumber)) {
        return -1;
      }
      if (parseInt(a.orderNumber) > parseInt(b.orderNumber)) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      } else return 1;
    });

    groupedMenuProducts.forEach((x) => {
      x.products = x.products.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else return 1;
      });
    });

    // console.log(`groupedMenuProducts`, groupedMenuProducts);
    return groupedMenuProducts;
  },
  categories: (state, getters) => {
    let result = getters.groupedProductsByType.map((type) => {
      const typeObject = {
        id: type.id,
        name: type.name,
        orderNumber:
          getters.categoriesFirebase.filter((x) => x.name == type.name)[0]
            ?.orderNumber ?? 0,
        domScrollTarget: type.domScrollTarget,
      };
      return typeObject;
    });

    result = result.sort((a, b) => {
      if (
        a.orderNumber === "" ||
        a.orderNumber === undefined ||
        a.orderNumber === null
      ) {
        if (a.name < b.name) {
          return -1;
        } else return 1;
      }
      if (
        b.orderNumber === "" ||
        b.orderNumber === undefined ||
        b.orderNumber === null
      ) {
        if (a.name < b.name) {
          return -1;
        } else return 1;
      }
      if (parseInt(a.orderNumber) < parseInt(b.orderNumber)) {
        return -1;
      }
      if (parseInt(a.orderNumber) > parseInt(b.orderNumber)) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      } else return 1;
    });
    // console.log(`sorted cats`, result);
    return result;
  },
  originName: (state) => {
    return state.originName;
  },
  backgroundMenuUrl: (state) => {
    return state.profile.backgroundMenuUrl;
  },
};

const actions = {
  async getProducts(
    { commit, state },
    { commerceId, originName, menuUUID, table }
  ) {
    state.loading = true;
    await getCommerceIdCategories(commerceId).then((response) => {
      commit("SET_FIREBASE_CATEGORIES", response);
    });
    await getAvailableProductsOnce(
      commerceId,
      originName,
      menuUUID,
      state.profile.productListId
    ).then((response) => {
      commit("SET_PRODUCTS", response.availableProducts);

      // Solves duplicate origin name setting with binding
      if (!table) {
        commit("SET_ORIGIN_NAME", response.originName);
      }
    });
    state.loading = false;
  },
  async getProfile({ commit }, { commerceId, menuUUID }) {
    return await getCommerceProfile(commerceId, menuUUID).then((profile) => {
      if (profile) {
        commit("SET_PROFILE", profile);
        return profile;
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
