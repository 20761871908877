<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
export default {
  computed: {
    shopName() {
      var shopName = this.$store.state.menu.profile.shopName;
      if (shopName == null) return "";

      return this.$store.state.menu.profile.shopName + " |";
    },
  },
  name: "App",
  metaInfo() {
    return {
      title: "Cargando...",
      titleTemplate: `${this.shopName} %s`,
      htmlAttrs: { lang: "es" },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
};
</script>
